<template>
  <div class="content">
    <siteHeader />
    <div class="container-fluid mt--9">
      <div>
        <card class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
          <template v-slot:header>
            <h3>Reciept</h3>
          </template>
          <div v-if="isLoading">
            <base-alert type="danger" dismissible>
              <span class="alert-inner--text">
                Record Deleted Successfully</span
              >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
          </div>
          <div>
            <div class="row">
              <div class="col text-left">
                <base-button
                  type="primary"
                  class="mb-4"
                  size="sm"
                  @click="$router.push('/reciept/create_reciept/undefined')"
                  >Create</base-button
                >

                <base-button
                  type="primary"
                  class="mb-4"
                  size="sm"
                  @click="printtable()"
                >
                  <i class="fa fa-print mr-2" size="5x"></i>
                  Export</base-button
                >
              </div>
              <div class="col" style="padding-left: 300px">
                <div class="search-wrapper panel-heading col-sm-12">
                  <el-input
                    type="search"
                    clearable
                    prefix-icon="el-icon-search"
                    placeholder="Search records"
                    v-model="searchQuery"
                    aria-controls="datatables"
                  >
                  </el-input>
                </div>
              </div>
            </div>
            <br />
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @row-dblclick="dbSelected"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
                sortable
              >
              </el-table-column>
              <el-table-column
                min-width="100px"
                align="right"
                label="Actions"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    <i
                      class="fas fa-trash-alt"
                      @click="showconfirm(props.row)"
                    ></i>
                    &nbsp;
                    <i class="fas fa-edit" @click="onEdit(props.row)"></i>
                    &nbsp;
                    <i class="far fa-eye" @click="openModel(props.row)"></i>
                    &nbsp; &nbsp;
                    <i
                      class="fa fa-print"
                      aria-hidden="true"
                      @click="print(props.row)"
                    ></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
    <modal v-model:show="modals.modal3">
      <template v-slot:header>
        <h6 class="modal-title" id="modal-title-default">Member Details</h6>
      </template>

      <div class="row">
        <div class="col-6">Is Member:</div>
        <div class="col-6">{{ this.mode_active.is_member }}</div>
      </div>

      <div class="row">
        <div class="col-md-6">Number:</div>
        <div class="col-6">{{ this.mode_active.number }}</div>
      </div>

      <div class="row">
        <div class="col-md-6">Date:</div>
        <div class="col-md-6">
          {{ this.mode_active.date }}
        </div>
      </div>

      <div v-if="this.mode_active.is_member != 'no'" class="row">
        <div class="col-md-6">Member Name:</div>
        <div class="col-md-6">
          <div v-for="item in MemberData" :key="item.id">
            <span v-if="item.id === this.mode_active.member_id">
              {{ item.full_name }}
            </span>
          </div>
        </div>
      </div>

      <div v-if="this.mode_active.is_member == 'no'" class="row">
        <div class="col-md-6">Name:</div>
        <div class="col-md-6">
          {{ this.mode_active.name }}
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">Amount($):</div>
        <div class="col-md-6">
          {{ this.mode_active.amount ? this.mode_active.amount : 0 }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">Comment:</div>
        <div class="col-md-6">
          {{ this.mode_active.comment }}
        </div>
      </div>
      <template v-slot:footer>
        <base-button type="link" class="ml-auto" @click="modals.modal3 = false"
          >Close
        </base-button>
      </template>
    </modal>
    <modal v-model:show="showEditModal" modal-classes="modal-secondary">
      <div style="text-align: center">
        <h3>Are you sure you want to delete this item?</h3>

        <base-button type="danger" @click="ondelete">Yes</base-button>
        <base-button type="success" @click="showEditModal = false"
          >No</base-button
        >
      </div>
    </modal>
  </div>
</template>
<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const recieptRepository = RepositoryFactory.get("reciept");
const memberRepository = RepositoryFactory.get("member");
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
export default {
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  name: "projects-table",
  computed: {
    pagedData() {
      return this.recieptData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.recieptData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (
            rowValue.includes &&
            rowValue.toLowerCase().includes(this.searchQuery)
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.recieptData.length;
    },
  },
  data() {
    return {
      recieptViewData: [],
      showEditModal: false,
      searchQuery: "",
      isLoading: false,
      tableData: [],
      recieptData: [],
      MemberData: [],
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      propsToSearch: ["is_member", "number", "name", "date"],
      tableColumns: [
        {
          prop: "number",
          label: "Number",
          minWidth: 100,
        },
        {
          prop: "name",
          label: "Name",
          minWidth: 100,
        },
        {
          prop: "address",
          label: "Address",
          // className: "showinprint",
        },

        {
          prop: "amount",
          label: "Amount($)",
          minWidth: 100,
        },

        {
          prop: "date",
          label: "Date",
          minWidth: 100,
        },
        {
          prop: "is_member",
          label: "Is Member",
          minWidth: 100,
        },
      ],
      fuseSearch: null,
      searchedData: [],
      model: {},
      mode_active: {},
      modals: {
        modal3: false,
      },
    };
  },
  methods: {
    printtable() {
      window.open(
        "https://shulsoftware.adass.com.au/api/index.php/Reciept/export",
        "_blank"
      );
    },

    async print(row) {
      window.open(
        "https://shulsoftware.adass.com.au/api/index.php/Reciept/print/" +
          row.id,
        "_blank"
      );
    },
    openModel(row) {
      this.mode_active = row;

      this.modals.modal3 = true;
    },
    showconfirm(row) {
      this.model = row;
      this.showEditModal = true;
    },
    async ondelete() {
      // this.model = row;
      this.model.is_delete = "Yes";
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await recieptRepository.delete(data);
      this.getList();
      this.showEditModal = false;
      if (response.data.status == 101) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    },
    dbSelected(row) {
      this.$router.push({
        name: "createreciept",
        params: { data: row.id },
      });
    },
    onEdit(row) {
      this.$router.push({
        name: "createreciept",
        params: { data: row.id },
      });
    },

    getList() {
      recieptRepository.getReciept().then((data) => {
        this.tableData = Object.freeze(data);
        this.recieptData = this.tableData.data;
        for (let index = 0; index < this.recieptData.length; index++) {
          const element = this.recieptData[index];
          element.amount = element.amount ? element.amount : 0;
          this.recieptData[index].amount = "$" + element.amount;
        }
      });
    },
    getmemberList() {
      memberRepository.getMember().then((data) => {
        this.tableData = Object.freeze(data);
        this.MemberData = this.tableData.data;
      });
    },
  },
  mounted() {
    this.getList();
    this.getmemberList();
  },
};
</script>
<style></style>
